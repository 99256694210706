@import "../../../_vars";

.championlist {
    display: flex;
    flex-flow: row wrap;
    max-width: 20em;
    max-height: 30em;
    overflow-y: auto;
    margin-left: auto;
    margin-right: auto;
    margin-top: 1em;


    & img {
        cursor: pointer;

        &:hover {
            filter: brightness(1.7); } }

    @include no-scrollbar; }


.championpicker-frame {
    padding: 1em;
    max-width: 20em;

    & h1, h4 {
        margin: 0.2em 0; }

    & h1 {
        @include cut-text; }


    & input {
        margin-left: 1em; }

    .champion-search-frame {
        display: flex;
        flex-flow: row wrap; }

    & .championpicker-existing {
        display: inline-block;
        margin-bottom: 1em;

        cursor: pointer;
        @include red-hoverable; } }

