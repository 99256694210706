@import "../../_vars";

.collection-frame {}


.song-list-frame {
    max-height: 25em;
    max-width: 30em;

    overflow-y: auto;
    @include no-scrollbar; }


.song-search-frame {
    display: flex;
    flex-flow: row nowrap;
    margin-bottom: 1em; }

.song-add-button {
    @include green-plus-button;
    display: inline-block;
    margin-left: 2em;
    transform: rotate(45deg); }
