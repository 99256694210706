@import "../../../_vars";

img {
    width: 1.1em;
    vertical-align: middle;
    padding: 0 0.5em; }

.youtube-link-row {
    display: block;
    cursor: pointer;
    border-radius: 6px;

    &.added {
        background-color: rgba(#78d678, .5); }

    @include transp-hoverable; }

