@import "./_vars";

.background {
  &::before {
    content: '';
    background-image: url('./assets/draven.jpg');
    background-size: cover;
    opacity: 0.5;
    height: 100%;
    width: 100%;
    position: absolute; }

  background-color: $bg;
  width: 100%;
  min-width: 100vw;
  height: 100%;
  min-height: 100vh;
  position: relative;

  &>.page {
    position: relative; } }

h1.main-title {
  margin-top: 0;
  padding-top: 0.4em; }

p, a, span, div, h1, h2, h3, h4, h5, h6, button {
  color: $text-color; }

button {
  border: 1px solid $border-color-light;
  background-color: rgba(#ffffff, 0);
  padding: 0.5em;
  cursor: pointer;
  box-shadow: 0 0 2px 1px $border-color-light;

  &:hover {
    background-color: rgba(#ffffff, .2); }

  &[disabled] {
    cursor: initial;

    &:hover {
      background-color: initial; } } }


.hor-centered {
  margin-left: auto;
  margin-right: auto;
  width: fit-content; }

.main-title {
  text-shadow: $text-shadow; }

.content {
  width: 70%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  margin: 1em auto 0;
  border-top: 1px solid $border-color-light;
  border-radius: 6px;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    height: 100%;
    width: 100%;
    backdrop-filter: $bdf;
    -webkit-backdrop-filter: $bdf;
    border-radius: 6px; }


  &>.content-page {
    position: relative;
    padding: 1em; } }

a {
  text-decoration: none; }
