@import "../../../_vars";

.songpicker-frame {
    padding: 2em;
    max-width: 25em;

    &>h1 {
        margin: 0;
        display: inline-block; }

    & .songpicker-song {
        cursor: pointer;
        border-radius: 1em;
        padding-left: .8em;
        padding-right: .8em;

        @include transp-hoverable;
        @include cut-text; }




    & .songpicker-selected-song {
        display: flex;

        & .songpicker-removelinkbutton {
            margin-right: 1em;
            @include red-cross-button; }
        & span {
            @include transp-hoverable;
            @include cut-text;
            border-radius: 1em; } }


    & h3 {
        display: inline-block;
        margin-right: 1em; }
    & .songpicker-list {
        max-height: 25em;
        overflow-y: auto;
        @include no-scrollbar; } }


