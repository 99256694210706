@import "../../../_vars";

.flex {
    display: flex;
    flex-flow: row wrap; }

.youtube-results {
    padding: 0 0.3em; }


.youtube-frame {
    border: solid 1px $border-color-light;
    border-radius: 6px;
    padding: 1em;

    &>h1 {
        margin-top: 0; }

    & .manual-add {
        & input {
            display: block;
            &.good-value {
                background-color: rgba(#a9ffa9, 1); }

            &.bad-value {
                background-color: rgba(#ff9292, 1); } } } }


.youtube-search-input {
    display: block; }

.youtube-search-button {
    margin-top: 1em;
    cursor: pointer;
    padding: .2em;
    border-style: none;
    padding: 0.4em;

    &:active {
        transform: translateY(2px);
        box-shadow: 0 0 2px 0 white; } }
