@import "../../_vars";

.championcollection-frame {

    &>h1 {
        margin-top: 0; }

    .championcollection-list {
        max-width: 40em;
        max-height: 35em;
        overflow-y: auto;

        @include no-scrollbar; }



    &>.filters {
        margin-bottom: 2em;
        display: flex;
        flex-flow: row nowrap;
        &>.champion-filter {
            margin-right: 2em; } } }
