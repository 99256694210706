@import "../../../_vars";

.champion-frame {
    display: inline-block;
    position: relative;
    cursor: pointer;

    &:hover {
        filter: brightness(1.7); } }

.champion-songcounter {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    right: -0.2em;
    bottom: -0.5em;
    background-color: rgba($bg, .8);
    border-radius: 10em;
    //padding: 0.5em
    width: 2em;
    height: 2em;

    &>span {
        display: block; } }
