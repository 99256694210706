@import "../../_vars";


.modal-frame {

    &::before {
        content: '';
        position: fixed;
        left: 0;
        top: 0;
        height: 100vh;
        width: 150vw;
        background-color: $bg;
        opacity: .7;
        transform: translateX(-50vw) translateY(-10vh); }

    box-shadow: 0 0 2em 1em $border-color-light;
    border-radius: 6px;

    &>.modal-element {
        position: relative; }

    &>.modal-close-button {
        position: absolute;
        top: 4px;
        right: 4px;
        @include red-cross-button;
        width: 1.7em;
        height: 1.7em; }


    position: fixed;
    width: fit-content;
    height: fit-content;
    right: 0;
    left: 0;
    top: 10vh;
    margin: auto;
    backdrop-filter: $bdf; }
