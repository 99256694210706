@import "../../_vars";

.order-button {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;

    &.ASC {
        &::after {
            content: '\21D3';
            margin-left: 0.3em;
            margin-right: 0.1em; } }
    &.DESC {
        &::after {
            content: '\21D1';
            margin-left: 0.3em;
            margin-right: 0.1em; } }
    &::after {
        content: '';
        font-size: 1.4em;

        min-height: 1.4em; }

    margin-right: 0.5em; }


