$bg: #121212;

$bdf: blur(6px) brightness(.5);

$text-color: rgba(#ffffff, .7);

$border-color-light: darken($text-color, 40%);
$border-color-dark: $bg;

$text-shadow: 2px 2px 1px $border-color-dark;


@mixin std-rounding {
    border-radius: 6px; }

@mixin transp-hoverable {
    transition: background-color 0.2s;
    &:hover {
        background-color: rgba($border-color-light, 0.3); } }

@mixin red-hoverable {
    transition: background-color 0.2s;
    &:hover {
        background-color: rgba(#ee8e8e, 0.3); } }


@mixin button($color, $bgimg) {
    cursor: pointer;
    display: block;
    background-color: $color;
    background-image: $bgimg;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;

    border-radius: 6px;
    border-color: $border-color-dark;
    box-shadow: 2px 2px 1px 0 $bg;
    height: 1.7em;
    min-width: 1.7em;

    &:hover {
        background-color: lighten($color, 10%); }

    &:disabled {
        cursor: not-allowed;
        background-color: #7e7e7e;
        &:hover {
            background-color: #7e7e7e; } } }

@mixin red-cross-button {
    @include button($color: #e35b52, $bgimg: url('./assets/x.png')); }

@mixin champion-button {
    @include button($color: #c5d6db, $bgimg: url('http://ddragon.leagueoflegends.com/cdn/5.5.1/img/ui/champion.png')); }

@mixin green-plus-button {
    @include button($color: #83d49a, $bgimg: url('./assets/x.png')); }

@mixin no-scrollbar {
    &::-webkit-scrollbar {
        display: none; }
    -ms-overflow-style: none;
    scrollbar-width: none; }

@mixin cut-text {
    text-overflow: ellipsis;
    overflow-x: hidden;
    white-space: nowrap; }



