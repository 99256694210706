@import "../../_vars";

.song-frame {
    display: flex;
    flex-flow: row nowrap;
    justify-content: left;
    align-items: center;

    & img {
        padding-left: 0; }

    & p {
        margin-top: 0.3em;
        margin-bottom: 0.3em; }

    & .song-link {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        border-radius: 6px;
        padding-left: 0.2em;
        padding-right: 0.2em;

        @include transp-hoverable; } }


.song-remove-button {
    @include red-cross-button;
    margin-right: 1em; }

.song-champion-button {
    @include champion-button;
    margin-right: 1em; }
