@import "../../_vars";

nav {
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    border-left: 1px solid $border-color-light;
    border-right: 1px solid $border-color-light;
    border-radius: 6px;

    & .navbar-item {
        padding: 1rem 0;
        width: 10em;
        text-align: center;
        margin: 0;
        display: inline-block;
        border-radius: 6px;
        text-shadow: $text-shadow;

        &:hover {
            backdrop-filter: $bdf; } } }
